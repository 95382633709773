<template>
	<section>
		<div class="banner">
			<video autoplay="autoplay" muted="muted" loop="loop" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/product.mp4">
                your browser does not support the video tag
            </video>
            <div class="section">
                <div class="section-content banner-con">
                    Gorex · 产品中心
                </div>
            </div>
		</div>
		<div class="section">
			<div class="section-content">
				
				<div class="part activity">
					<div class="activity-item">
                        <div class="activity-title">微信小程序商城</div>    
                        <div class="activity-oper">数字化电商解决方案,让企业经营更高效,3分钟开通店铺,坐享12亿微信流量红利,助力商家实现超强流量转化!</div>
                        <div class="activity-button main-color" @click="product_detail('1')">立即选购→</div>   
                        <div class="card-img">
                            <div class="card-text">云商城</div>
                            <img class="card-img" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/product02.png">
                        </div>        
                    </div>
                    <div class="activity-item">
                        <div class="activity-title">微信小程序直播号（即将上线）</div>    
                        <div class="activity-oper">无缝对接视频号,一键绑定即可边播边卖,无需跳转即可下单,百种热门营销工具助力抢占直播流量！</div>
                        <div class="activity-button main-color" @click="hkmall_detail('2')">立即选购→</div>
                        <div class="card-img">
                            <div class="card-text">云直播</div>
                            <img class="card-img" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/product02.png">
                        </div>        
                    </div>
                    <div class="activity-item">
                        <div class="activity-title">微信小程序服务号（即将上线）</div>    
                        <div class="activity-oper">服务预定,排队叫号,丰富的行业模板,抽奖系统工具活动制作神器免费一键制作发布！</div>
                        <!-- <div class="activity-button main-color active">敬请期待→</div>   -->
                        <div class="activity-button main-color" @click="fxmall_detail('3')">立即选购→</div>    
                        <div class="card-img">
                            <div class="card-text">云服务</div>
                            <img class="card-img" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/product02.png">
                        </div>        
                    </div>
				</div>


				<div class="part online-con">
					<div class="online-left">
                        <div class="online-title">云商城</div>
                        <div class="online-pro">搭建多方位营销矩阵为企业搭建一站式营销中心，可轻松对接多个营销终端。</div>
                        <div class="online-price">标准版 ￥ 2980.00/年</div>
                        <div class="online-button" @click="product_detail('1')">查看详情 →</div>
                    </div>
                    <div class="online-right">
                        <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/online_bg.png">
                    </div>

				</div>
                <div class="part product">
                    
                    <div class="product-item">
                        <div class="product-left">
                            <p class="product-title">云服务(内测中)</p>
                            <p class="product-oper">享有各类功能，性价比高产品稳定</p>
                        </div>
                        <div class="product-img">
                            <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/product_n2.png">
                        </div>
                    </div>
                    <div class="product-item">
                        <div class="product-left">
                            <p class="product-title">云直播(开发中)</p>
                            <p class="product-oper">享有各类功能，性价比高产品稳定</p>
                        </div>
                        <div class="product-img">
                            <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/product_n3.png">
                        </div>
                    </div>
                    <div class="product-item">
                        <div class="product-left">
                            <p class="product-title">云估价(开发中)</p>
                            <p class="product-oper">享有各类功能，性价比高产品稳定</p>
                        </div>
                        <div class="product-img">
                            <img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/product_n1.png">
                        </div>
                    </div>
                </div>

                <div class="part data">
                    <div class="title-module">
                        <div class="main-title">数据安全保障</div>
                        <div class="sub-title">
                            <p>为企业搭建一站式营销中心，可轻松</p>
                            <p>对接多个营销小程序</p>
                        </div>
                    </div>
                    <div class="part1-con">
                        <div class="p1-con-item">
                            <i class="p1-con-icon iconfont icon-fuwu1"></i>
                            <p class="p1-con-title">服务能力稳定性保障</p>
                            <div class="p1-con-con">
                                <p>阿里云提供计算服务<p/>
                                <p>分布式架构，异地多机房负载</p>
                            </div>  
                        </div>
                        <div class="p1-con-item">
                            <i class="p1-con-icon iconfont icon-mimabaohu" style="font-size: 36px;"></i>
                            <p class="p1-con-title">数据机密性保障</p>
                            <div class="p1-con-con">
                                <p>多重身份校验机制</p>
                                <p>连接加密，关键数据加密存储</p>
                            </div>  
                        </div>
                        <div class="p1-con-item">
                            <i class="p1-con-icon iconfont icon-jiankong"></i>
                            <p class="p1-con-title">全面监控与审计</p>
                            <div class="p1-con-con">
                                <p>专业的运维安全审计</p>
                                <p>管控系统全面监控，实时告警和溯源</p>
                            </div>  
                        </div>
                        <div class="p1-con-item">
                            <i class="p1-con-icon iconfont icon-iconfont-5"></i>
                            <p class="p1-con-title">全面防护系统</p>
                            <div class="p1-con-con">
                                <p>享有各类功能，性价比高，产品稳定</p>
                                <p>每周持续更新</p>
                            </div>  
                        </div>
                    </div>
                </div>





			</div>
		</div>
	</section>
</template>
<script type="text/javascript">
	export default {
    data(){
        return {
        	url:'',
        	activity_data:{},
        	swiper_data:[]

        }
    },
	mounted(){
		console.log('this is index');
		this.activity_f();
		// this.product_f();
	},
	methods: {
		activity_f(){
			let _this = this;
			this.axios.post('/action/nologin/list', {
                list: {
                	start: 1,
                	count: 4
               	} 
            })
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {

                    _this.$nextTick(function () {
                        _this.activity_data = res.extra.list;
                    })
                    
                } else {
                    _this.logining = false;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		},
		activity_detail(id){
            this.$router.push({name: 'event_details',query:{id:id}});
		},
		
		product_detail(id){
            this.$router.push({name: 'xmall_detail',query:{id:id}});
		},
        hkmall_detail(id){
            this.$router.push({name: 'hkmall_detail',query:{id:id}});
        },
        fxmall_detail(id){
            this.$router.push({name: 'fxmall_detail',query:{id:id}});
        },
	},
  
  // router
}

</script>
<style type="text/css" scoped>
	.banner{
        width: 100%;
        position: relative;
    }
    .banner video{
        width: 100%;
    }
    .banner-con{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        font-size: 24px;
        color: #fff;
        font-weight: bold;
    }
    
    .page-title{
    	font-size: 24px;
    	line-height: 24px;
    	font-weight: bold;
    	color: #333333;
    	padding-left: 45px;
    }
    .search{
    	width: 328px;
    	border-bottom: solid 1px #797979;
    	display: flex;
    	align-items: center;
    	margin-top: 30px;
    }
    .search input{
    	flex: 1;
    }
    .search i{
    	flex-shrink: 0;
    	font-size: 22px;
    	font-weight: bold;
    	color: #333;
    	margin-left: 12px;
    }

    .activity{
        width: 100%;
        display: flex;
        margin-top: 60px;
    }
    .activity-item{
        width: 25%;
        position: relative;
        margin-right: 26px;
        padding: 40px 20px 30px 30px;
        box-sizing: border-box;
        width: 33.33%;
        min-height: 148px;
        background: #fff;
        border: 1px solid #ebecec;
        background: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/product01.png) 0% 0% / cover no-repeat;
    }
    .activity-item:last-child{
        margin-right: 0;
    }
    .activity-item:hover{
        border: 1px solid #ededed;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
        transition: box-shadow .3s ease-out;
    }
    .activity-title{
        font-size: 16px;
        height: 24px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #373d41;
        font-weight: 700;
        margin-bottom: 11px;
    }
    .activity-oper{
        font-size: 14px;
        color: #666;
        height: 48px;
        line-height: 24px;
        overflow: hidden;
        margin: 0 0 14px;
    }
    .activity-button{
        display: inline-block;
        margin-top: 40px;
        text-align: left;
        line-height: 20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        letter-spacing: 0;
        width: 113.3px;
        height: 20px;
        cursor: pointer;
    }
    .activity-button:hover{
        /*font-size: 14px;*/
        transform: scale(1.1);
    }
    .activity-button.active{
        color: #999;
    }
    .card-img{
        position: absolute;
        width: 140px;
        height: 32px;
        top: -6.5px;
        right: -0.5px;
    }
    .card-text{
        user-select: none;
        position: absolute;
        text-align: center;
        width: 140px;
        height: 32px;
        font-size: 14px;
        font-weight: 700;
        line-height: 32px;
        color: #fff;
        overflow: hidden;
        top: -6.5px;
        right: -0.5px;
        z-index: 9;
    }
    .card-img{
        /*width: 100%;*/
    }


    .online-con{
        padding: 50px 100px 20px 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        border-radius: 30px;
        margin-top: 60px;
    }
    .online-left{
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        margin-right: 80px;
    }
    .online-title{
        font-size: 32px;
        line-height: 32px;
        color: #333333;
        font-weight: bold;
    }
    .online-pro{
        font-size: 14px;
        color: #333333;
        margin-top: 14px;
        font-weight: bold;
    }
    .online-price{
        font-size: 18px;
        line-height: 18px;
        color: #169BD5;
        font-weight: bold;
        margin-top: 50px;
        font-family: "Microsoft YaHei";
    }
    .online-button{
        width: 153px;
        height: 35px;
        border-radius: 5px;
        border: 1px solid #169BD5;
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #169BD5;
        cursor: pointer;
    }
    .online-button:hover{
        color: #E79E0D;
        border: 1px solid #E79E0D;
    }
    .online-right img{
        width: 608px;
    }

    .product{
        width: 100%;
        height: 180px;
        display: flex;
        align-items: center;
        margin-top: 60px;
    }
    .product-item{
        width: 33.33%;
        border-radius: 10px;
        background-color: #fff;
        padding: 30px 20px 20px 20px;
        display: flex;
        justify-content: space-between;
        margin-right: 60px;
    }
    .product-item:last-child{
        margin-right: 0;
    }
    .product-item:hover{
        border: 1px solid #ededed;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
        transition: box-shadow .3s ease-out;
    }
    .product-left{
        flex-shrink: 0;
        margin-right: 12px;
    }
    .product-title{
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 14px;
    }
    .product-oper{
        font-size: 12px;
        color: #848282;
        padding-top: 12px;
    }
    .product-img img{
        width: 129px;
    }

    .data{
        width: 100%;
        border-radius: 30px;
        background-color: #fff;
        margin-top: 60px;
        overflow: hidden;
    }
    .part1-con{
        width: 100%;
        display: flex;
        margin-left: 20px;
        padding: 0 30px 100px 30px;
    }
    .p1-con-item{
        width: 25%;
        margin-right: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 60px;
    }
    .p1-con-item:last-child{
        margin-right: 0;
    }
    .p1-con-icon{
        line-height: 40px;
        font-size: 40px;
        font-weight: 300;
        color: #6f6868;
    }
    .p1-con-title{
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #666666;
        padding-top: 14px;
    }
    .p1-con-con{
        font-size: 12px;
        color: #848282;
        padding-top: 14px;
        text-align: center;
    }
    

    



</style>